<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              楚润餐饮
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            登录 👋🏻
          </p>
          <p class="mb-2">
            请登录您的帐户
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="mobile"
              outlined
              label="手机号"
              :rules="mobileRules"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :rules="pwdRules"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密码"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                v-model="remember"
                label="记住我"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                忘记密码?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="login"
            >
              登录
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const mobile = ref('')
    const password = ref('')
    const remember = ref(false)

    return {
      isPasswordVisible,
      mobile,
      password,
      remember,
      mobileRules: [
        v => !!v || '请输入手机号',
        v => v.length === 11 || '手机号格式不正确',
        v => /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(v) || '手机号格式不正确',
      ],
      pwdRules: [
        v => !!v || '请输入密码',
        v => (v.length > 8 && v.length < 16) || '密码必须为8-16位',
      ],
      login() {
        this.$store.dispatch('user/login', {
          mobile: mobile.value,
          password: password.value,
          remember: remember.value,
        }).then(() => {
          this.$toast.success('登录成功')
          setTimeout(() => {
            this.$router.push((this.$route.query && this.$route.query.redirect) || '/')
          }, 1000)
        })
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
